import request from '@/utils/request';
import axios from 'axios';

// -------------------通用--------------------------------
//
export function postRequest(url, params) {
  return request.post(url, params);
}
export function formRequest(url, params) {
  return request.post(url, params, {
    headers: {
      'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'
    }
  });
}
//
export function getRequest(url, params) {
  return request.get(url, { params });
}
export function exportFile(url, params) {
  return request.get(url, { params, responseType: 'blob' });
}
//无人机任务列表
export function uavPageApi(params) {
  return request.get('/api/operate/customer/uav/page', { params });
}
//添加无人机任务
export function uavAddApi(params) {
  return request.post('/api/operate/customer/uav/add', params);
}
//更新无人机任务
export function uavUpdateApi(params) {
  return request.post('/api/operate/customer/uav/update', params);
}
//完成上传
export function uavFinishUploadApi(params) {
  return request.post('/api/operate/customer/uav/finish/upload', params);
}
//重新上传
export function uavReuploadApi(params) {
  return request.post('/api/operate/customer/uav/reupload', params);
}
//开始上传
export function uavStartApi(params) {
  return request.post('/api/operate/customer/uav/start/upload', params);
}
//取消任务
export function uavCancelApi(params) {
  return request.post('/api/operate/customer/uav/cancel', params);
}
//无人机巡田结果
export function uavViewPageApi(params) {
  return request.get('/api/operate/customer/uavview/page', { params });
}
// 无人机巡田结果详情
export function uavMergeResultApi(params) {
  return request.get('/api/operate/customer/uav/merge/result', { params });
}
// 无人机巡田遥感结果
export function remoteSenseViewApi(params) {
  return request.get('/api/operate/customer/uav/remote/sense/view', { params });
}
//获取ossKey
export function uavGetOssKeyApi(params) {
  return request.get('/api/operate/customer/uav/getosskey', { params });
}

/** 精准种植 */
// 产量地图列表
export function yieldMapIndexApi(params) {
  return request.get('/api/customer/operate/precision/yieldmap/index', { params });
}
// 产量地图详情
export function yieldMapViewApi(params) {
  return request.get('/api/customer/operate/precision/yieldmap/view', { params });
}

/** 土壤墒情 */
export function soilMoistureHistoryApi(params) {
  return request.post('/api/iot2/soil/moisture/history', params);
}

/** 土壤墒情详情 */
export function soilMoistureHistoryReportApi(params) {
  return request.post('/api/iot2/soil/moisture/history/report', params);
}

//部门列表
export function departmentsList(params) {
  return request.get('/api/operate/common/sys/departments', { params });
}
//用户列表
export function usersList(params) {
  return request.get('/api/operate/common/sys/users', { params });
}

//用户列表
export function documentTypeList() {
  return request.get('/api/common/document/type/page', {
    params: { pageSize: 9999 }
  });
}

//菜单列表
export function menuTreeApi() {
  return request.get('/api/customer/security/security/view/functionTree', {
    params: { pageSize: 9999 }
  });
}

// -----------------------------------------------------
//登录
export function loginApi(params) {
  return request.post('/api/customer/security/security/login', params);
}

export function downloadUrl(url) {
  return axios.get(url, { responseType: 'blob' });
}
//会议列表
export function serviceList(params) {
  return request.get('/api/operate/govserv/meeting/page', { params });
}

//新增会议服务
export function createMeeting(params) {
  return request.post('/api/operate/govserv/meeting/create', params, {
    headers: {
      'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'
    }
  });
}

//更新会议服务
export function uploadMeeting(params) {
  return request.post('/api/operate/govserv/meeting/update', params, {
    headers: {
      'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'
    }
  });
}

//培训列表
export function trainingList(params) {
  return request.get('/api/operate/govserv/training/page', { params });
}
//新增培训服务
export function createTraining(params) {
  return request.post('/api/operate/govserv/training/create', params, {
    headers: {
      'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'
    }
  });
}

//更新培训服务
export function uploadTraining(params) {
  return request.post('/api/operate/govserv/training/update', params, {
    headers: {
      'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'
    }
  });
}

//科技列表
export function technologyList(params) {
  return request.get('/api/operate/govserv/technical/page', { params });
}
//新增科技服务
export function createTechnology(params) {
  return request.post('/api/operate/govserv/technical/create', params, {
    headers: {
      'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'
    }
  });
}

//更新科技服务
export function uploadTechnology(params) {
  return request.post('/api/operate/govserv/technical/update', params, {
    headers: {
      'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'
    }
  });
}
//农机列表
export function machineList(params) {
  return request.get('/api/operate/govserv/machine/page', { params });
}
//新增农机服务
export function createMachine(params) {
  return request.post('/api/operate/govserv/machine/create', params, {
    headers: {
      'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'
    }
  });
}
//更新农机服务
export function uploadMachine(params) {
  return request.post('/api/operate/govserv/machine/update', params, {
    headers: {
      'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'
    }
  });
}
//新增无人机服务
export function createUav(params) {
  return request.post('/api/operate/govserv/uav/create', params, {
    headers: {
      'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'
    }
  });
}
//更新无人机服务
export function uploadUav(params) {
  return request.post('/api/operate/govserv/uav/update', params, {
    headers: {
      'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'
    }
  });
}
//会议发布
export function publishedService(params) {
  return request.post('/api/operate/govserv/business/service/publish', params);
}

//取消会议发布
export function unpublishedService(params) {
  return request.post('/api/operate/govserv/business/service/unpublished', params);
}

//查看详情
export function serviceDetail(params) {
  return request.get('/api/operate/govserv/business/service/view', { params });
}

//会议发布
export function publishedService1(params, url) {
  return request.post(url, params);
}

//取消会议发布
export function unpublishedService1(params, url) {
  return request.post(url, params);
}

//查看详情
export function serviceDetail1(params, url) {
  return request.get(url, { params });
}
//所有字典类型
export function getDicAll() {
  return request.get('/api/common/dict/all', {});
}
//根据条件查询服务申请列表
export function applyList(params) {
  return request.get('/api/operate/govserv/service/apply/page', { params });
}
//根据条件查询服务申请列表
export function applyListUrl(params, url) {
  return request.get(url, { params });
}
//根据条件查询服务申请列表
export function applyList1(params) {
  return request.get('/api/operate/govserv/tech/apply/page', { params });
}

//服务通过
export function applyApprove(params) {
  return request.post('/api/operate/govserv/service/apply/approve', params);
}
//服务通过
export function applyApprove1(params, url) {
  return request.post(url, params);
}
//地图
export function amapRegeo(params) {
  return request.post('/api/common/amap/regeo', params);
}

// -------------------问答--------------------------------
//问题列表
export function questionList(params) {
  return request.get('/api/operate/govserv/question/page', { params });
}
//问题列表
export function answerDetail(params) {
  return request.get('/api/operate/govserv/question/view/first/answer', {
    params
  });
}
//添加问题回答
export function answer(params) {
  return request.post('/api/operate/govserv/question/add/answer', params);
}
//修改问题回答
export function answerEdit(params) {
  return request.post('/api/operate/govserv/question/update/answer', params);
}

// -------------------文章--------------------------------
//文章列表
export function articleList(params) {
  return request.get('/api/operate/govserv/article/page', { params });
}
//新增文章
export function createArticle(params) {
  return request.post('/api/operate/govserv/article/create', params, {
    headers: {
      'Content-Type': 'multipart/form-data;'
    }
  });
}
//修改文章
export function updateArticle(params) {
  return request.post('/api/operate/govserv/article/update', params, {
    headers: {
      'Content-Type': 'multipart/form-data;'
    }
  });
}
//文章详情
export function articleDetail(params) {
  return request.get('/api/operate/govserv/article/view', { params });
}
//频道列表
export function channelList(params) {
  return request.get('api/operate/article/channel/page', { params });
}
//发布文章
export function publishArticle(params) {
  return request.post('/api/operate/govserv/article/publish', params);
}
//取消文章
export function unpublishedArticle(params) {
  return request.post('/api/operate/govserv/article/unpublished', params);
}
//删除文章
export function deleteArticle(params) {
  return request.post('/api/operate/govserv/article/delete', params);
}
/**
 *
 * 地块相关
 */
// 地块列表
export function landList(params) {
  return request.get('/api/operate/planting/land/page', { params });
}
// 地块列表（大屏，无需登陆态）
export function screenLandList(params) {
  return request.get('/api/screen/znyj/land/page', { params });
}

// 获取所有障碍物图标
export function allLandInfos(params) {
  return request.get('/api/operate/planting/land/all/boundaries', { params });
}

// 获取所有障碍物图标
export function imageAll(params) {
  return request.get('/api/common/planting/land/obstacle/imageAll', { params });
}

// 新增地块
export function landAdd(params) {
  return request.post('/api/operate/planting/land/add', params);
}

// 根据地块ID获取地块详情
export function landView(params) {
  return request.get('/api/operate/planting/land/view', { params });
}

// 根据地块ID获取地块详情
export function landUpdate(params) {
  return request.post('/api/operate/planting/land/update', params);
}

// 地块信息（行政区或者分组）
export function landUsePage(params) {
  return request.get('/api/operate/common/land/use/page', { params });
}

// 地块边界
export function landScopeQuery(params) {
  return request.get('api/operate/common/land/scope/query', { params });
}

// 数据字典所有
export function dictAll(params) {
  return request.get('/api/common/dict/all', { params });
}

// 数据字典数组
export function dictListApi(params) {
  return request.get('/api/common/dict/list', { params });
}
// --------------------------专家-------------------------------
//新增专家
export function createExpert(params) {
  return request.post('/api/operate/govserv/expert/add', params, {
    headers: {
      'Content-Type': 'multipart/form-data;'
    }
  });
}
//更新专家
export function updateExpert(params) {
  return request.post('/api/operate/govserv/expert/update', params, {
    headers: {
      'Content-Type': 'multipart/form-data;'
    }
  });
}
//发布专家
export function publishExpert(params) {
  return request.post('/api/operate/govserv/expert/publish', params);
}
//取消发布专家
export function unpublishExpert(params) {
  return request.post('/api/operate/govserv/expert/unpublish', params);
}
//查询专家信息
export function detailExpert(params) {
  return request.get('/api/operate/govserv/expert/edit', { params });
}

//查询专家信息
export function expertList(params) {
  return request.get('/api/operate/govserv/expert/page', { params });
}

// --------------------------无人机-------------------------------
//农机列表
export function uavList(params) {
  return request.get('/api/operate/govserv/uav/page', { params });
}

// --------------------------商品-------------------------------
//商品列表
export function productionList(params) {
  return request.get('/api/operate/govserv/production/page', { params });
}

// --------------------------种植方案-------------------------------
//方案列表
export function plantingList(params) {
  return request.get('/api/customer/planting/planting/scheme/page', { params });
} //方案列表
export function plantingList1(params) {
  return request.get('/api/operate/planting/scheme/page', { params });
}
//子方案列表
export function plantingSubList(params) {
  return request.get('/api/operate/planting/scheme/detail/page', { params });
}
//创建
export function createSupply(params) {
  return request.post('/api/operate/planting/scheme/create', params, {
    headers: {
      'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'
    }
  });
}
//修改
export function updateSupply(params) {
  return request.post('/api/operate/planting/scheme/update', params, {
    headers: {
      'Content-Type': 'multipart/form-data;'
    }
  });
}

//启用
export function publishPlanting(params) {
  return request.post('/api/operate/planting/scheme/enable', params, {
    headers: {
      'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'
    }
  });
}
//停用
export function unpublishedPlanting(params) {
  return request.post('/api/operate/planting/scheme/disable', params, {
    headers: {
      'Content-Type': 'multipart/form-data;'
    }
  });
}
//停用
export function deletePlantings(params) {
  return request.post('/api/operate/planting/scheme/delete', params, {
    headers: {
      'Content-Type': 'multipart/form-data;'
    }
  });
}
//种植季
export function seasonList(params) {
  return request.get('/api/operate/planting/use/season/current', { params });
}
//种植作物
export function categoryList(params) {
  return request.get('/api/operate/conf/crop/category/list', { params });
}
//获取农事操作业务类型列表
export function farmingList(params) {
  return request.get('api/common/planting/land/conf/farming/types/without/patrol', { params });
}
//获取农事操作业务类型列表
export function farmingListAll(params) {
  return request.get('/api/common/planting/land/conf/farming/types', {
    params
  });
}
// //获取农事操作业务类型列表
export function categorySubList(params) {
  return request.get('/api/customer/conf/farming/type/list/category', {
    params
  });
}
//创建农事操作
export function createPlanting(params) {
  return request.post('/api/operate/planting/scheme/detail/create', params);
}
//修改农事操作
export function updatePlanting(params) {
  return request.post('/api/operate/planting/scheme/detail/update', params);
}
//删除农事操作
export function deletePlanting(params) {
  return request.post('/api/operate/planting/scheme/detail/delete', params, {
    headers: {
      'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'
    }
  });
}

//获取农事记录
export function farmWorkList(params) {
  return request.get('/api/operate/planting/history/farming/page', { params });
}
//获取农事记录
export function farmWorkList1(params) {
  return request.get('/api/operate/planting/patrol/damage/page', { params });
}
//获取巡田记录
export function patrolList(params) {
  return request.get('/api/operate/planting/history/farming/page/patrol', {
    params
  });
}

//创建农事记录
export function createWorkFarming(params) {
  return request.post('/api/operate/planting/history/farming/workFarming', params, {
    headers: {
      'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'
    }
  });
}

// 接受无人机申请
export function receiveUavApply(params) {
  return request.post('/api/operate/govserv/uav/apply/receive', params, {
    headers: {
      'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'
    }
  });
}

// --------------------------供应-------------------------------
// 获取供应商品列表;
export function supplyList(params) {
  return request.get('/api/operate/govserv/commodity/supply/page', { params });
}
//发布
export function publishSupply(params) {
  return request.post('/api/operate/govserv/commodity/supply/publish', params);
}
//取消发布
export function unpublishSupply(params) {
  return request.post('/api/operate/govserv/commodity/supply/unpublished', params);
}
// --------------------------求购-------------------------------
// 获取供应商品列表;
export function seekList(params) {
  return request.get('/api/operate/govserv/commodity/seek/page', { params });
}
//发布
export function publishSeek(params) {
  return request.post('/api/operate/govserv/commodity/seek/publish', params);
}
//取消发布
export function unpublishSeek(params) {
  return request.post('/api/operate/govserv/commodity/seek/unpublished', params);
}

// --------------------------农产品供应-------------------------------
// 获取供应商品列表;
export function supplyListAgriculture(params) {
  return request.get('/api/operate/govserv/agriculture/supply/page', {
    params
  });
}
//发布
export function publishSupplyAgriculture(params) {
  return request.post('/api/operate/govserv/agriculture/supply/publish', params);
}
//取消发布
export function unpublishSupplyAgriculture(params) {
  return request.post('/api/operate/govserv/agriculture/supply/unpublished', params);
}
//创建
export function createSupplyAgriculture(params) {
  return request.post('/api/operate/govserv/agriculture/supply/create', params, {
    headers: {
      'Content-Type': 'multipart/form-data;'
    }
  });
}
//创建
export function updateSupplyAgriculture(params) {
  return request.post('/api/operate/govserv/agriculture/supply/update', params, {
    headers: {
      'Content-Type': 'multipart/form-data;'
    }
  });
}
//创建
export function supplyAgricultureDetail(params) {
  return request.get('/api/operate/govserv/agriculture/supply/view', {
    params
  });
}
//创建
export function deleteSupplyAgriculture(params) {
  return request.post('/api/operate/govserv/agriculture/supply/delete', params);
}
//取消发布
// export function unpublishSupplyAgriculture(params) {
//   return request.post('/api/operate/govserv/agriculture/supply/unpublished', params);
// }
// --------------------------农产品求购-------------------------------
// 获取供应商品列表;
export function seekListAgriculture(params) {
  return request.get('/api/operate/govserv/agriculture/seek/page', { params });
}
//发布
export function publishSeekAgriculture(params) {
  return request.post('/api/operate/govserv/agriculture/seek/publish', params);
}
//取消发布
export function unpublishSeekAgriculture(params) {
  return request.post('/api/operate/govserv/agriculture/seek/unpublished', params);
}

// --------------------------入库品类-------------------------------

//增加品类
export function addProduction(params) {
  return request.post('/api/operate/govserv/production/add', params);
}
//增加品类
export function editProduction(params) {
  return request.post('/api/operate/govserv/production/update', params);
}

//增加品类
export function listProduction(params) {
  return request.get('/api/operate/govserv/production/page', { params });
}
//增加品类
export function deleteProduction(params) {
  return request.post('/api/operate/govserv/production/delete', params);
}

//增加品类
export function listProductionreport(params) {
  return request.get('/api/operate/govserv/productionreport/department/list', {
    params
  });
}
//增加品类
export function listProductionreportList(params) {
  return request.get('/api/operate/govserv/productionreport/page', { params });
}
// --------------------------关联地块-------------------------------
//地块列表
export function landListContent(params) {
  return request.get('/api/operate/planting/land/page', { params });
}
//关联地块接口
export function startPlantingWithScheme(params) {
  return request.post('/api/operate/planting/use/usePlantingScheme', params, {
    headers: {
      'Content-Type': 'multipart/form-data;'
    }
  });
}
//关联地块列表
export function schemeLandList(params) {
  return request.get('/api/operate/planting/use/page/scheme/land', { params });
}

// --------------------------作业表-------------------------------
export function plantingHomepage(params) {
  return request.get('/api/operate/scheme/task/land/homeWorkPage', { params });
}
export function farmingChart(params) {
  return request.get('/api/operate/scheme/task/land/view/by/scheme/farming', {
    params
  });
}

//查询方案任务详情   左边
export function plantingOwnerDetail(params) {
  return request.get('/api/operate/scheme/task/land/owner/history/farming/by/farming', { params });
}
//查询方案任务详情   右边
export function plantingDetail(params) {
  return request.get('/api/operate/scheme/task/land/history/farming', {
    params
  });
}

// --------------------------出入库统计-------------------------------
//最右边导出   导出库存记录

export function exportStockHistory(params) {
  return request.get('/api/operate/govserv/productionreport/record/export', {
    params,
    responseType: 'blob'
  });
}
//最左边导出  导出农资及库存列表
export function exportStockHistory1(params) {
  return request.get('/api/operate/govserv/productionreport/stock/export', {
    params,
    responseType: 'blob'
  });
}
//最左边导出  导出农资及库存列表
export function exportStockHistory2(params) {
  return request.get('/api/operate/govserv/productionreport/department/export', { params, responseType: 'blob' });
}
//导出水卡
export function exportCards(params) {
  return request.get('/api/iot/sdsj/pay/cards/export', {
    params,
    responseType: 'blob'
  });
}
//
export function recordList(params) {
  return request.get('/api/operate/govserv/productionreport/page/production/record', { params });
}
export function departments(params) {
  return request.get('/api/operate/govserv/productionreport/stock/departments', { params });
}

// --------------------------文档管理-------------------------------
//列表
export function documentList(params) {
  return request.get('/api/operate/govserv/document/page', { params });
}

//创建
export function documentAdd(params) {
  return request.post('/api/operate/govserv/document/add', params, {
    headers: {
      'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'
    }
  });
}
//创建
export function documentUpdate(params) {
  return request.post('/api/operate/govserv/document/update', params, {
    headers: {
      'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'
    }
  });
}

//创建
export function documentDelete(params) {
  return request.post('/api/operate/govserv/document/delete', params);
}

// --------------------------运维管理-------------------------------
// 运维数据
export function serverInfo(params) {
  return request.get('/api/common/monitor/server/info', { params });
}

// sql数据源
export function sqlList(params) {
  return request.get('/druid/sql.json', { params });
}
// 数据库监控
export function dataSource(params) {
  return request.get('/druid/datasource.json', { params });
}

// --------------------------通用列表数据-------------------------------
// 部门列表
export function departmentList(params) {
  return request.get('/api/operate/common/sys/departments', { params });
}

// 执行人列表
export function sysUsers(params) {
  return request.get('/api/operate/common/sys/users', { params });
}

// 负责人列表
export function sysUserPage(params) {
  return request.get('/api/common/sys/user/page', { params });
}

// 种植类型
export function farmingTypes(params) {
  return request.get('/api/operate/common/planting/farming/types', { params });
}

// 种植地块列表
export function plantLands(params) {
  return request.get('/api/operate/common/planting/lands', { params });
}

// 农资列表
export function productionReport(params) {
  return request.get('/api/operate/planting/history/farming/storage/page', {
    params
  });
}

// --------------------------通用列表数据-------------------------------
// 列表
export function cropCategories(params) {
  return request.get('/api/common/planting/crop/categories', { params });
}
// 列表
export function cropCategoryList(params) {
  return request.get('/api/operate/machine/machine/page', { params });
}
//创建
export function cropCategoryCreate(params) {
  return request.post('/api/operate/machine/machine/create', params, {
    headers: {
      'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'
    }
  });
}
//更新
export function cropCategoryUpdate(params) {
  return request.post('/api/operate/machine/machine/update', params, {
    headers: {
      'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'
    }
  });
}
//
export function cropCategoryPush(params) {
  return request.post('/api/operate/machine/machine/enable', params);
}
//
export function cropCategorydisPush(params) {
  return request.post('/api/operate/machine/machine/disable', params);
}
export function cropCategoryDelete(params) {
  return request.post('/api/operate/machine/machine/delete', params);
}
export function logHistory(params) {
  return request.post('/api/iot/opt/log/page', params);
}
export function siqingHistory(url, params) {
  return request.post(url, params);
}

// 土壤元素数据
export function soilNutrient(params) {
  return request.get('/api/common/soil/nutrient', { params });
}
